window.requestAnimFrame = (function(){
  return  window.requestAnimationFrame       ||
          window.webkitRequestAnimationFrame ||
          window.mozRequestAnimationFrame    ||
          function( callback ){
            window.setTimeout(callback, 1000 / 60);
            };
})();

var Utils = {};
Utils.addEvent = (function () {
  var filter = function(el, type, fn) {
    for ( var i = 0, len = el.length; i < len; i++ ) {
      utils.addEvent(el[i], type, fn);
    }
  };
  if ( document.addEventListener ) {
    return function (el, type, fn) {
      if ( el && el.nodeName || el === window ) {
        el.addEventListener(type, fn, false);
      } else if (el && el.length) {
        filter(el, type, fn);
      }
    };
  }
  return function (el, type, fn) {
    if ( el && el.nodeName || el === window ) {
      el.attachEvent('on' + type, function () { return fn.call(el, window.event); });
    } else if ( el && el.length ) {
      filter(el, type, fn);
    }
  };
})();

var mobileMenuBreakpoint = 1056;

var Base = {
  // All pages
  'common': {
    init: function() {

      const originReg = new RegExp(location.hostname, 'i');
      


      const { body, documentElement } = document;
      let { scrollTopVal } = document.documentElement;
      function lockScroll() {
        scrollTopVal = documentElement.scrollTop;
        body.style.top = -scrollTopVal + 'px';
        body.style.overflowY = null;
        documentElement.style.overflowY = null;
        body.classList.add('lockscroll');
      }
      function unLockScroll() {
        body.classList.remove('lockscroll');
        documentElement.scrollTop = scrollTopVal;
        body.style.removeProperty('top');
      }

      
      var mainnav = document.querySelector('.header');
      var scrollTop = window.scrollY;
      var lastScrollTop = 0;
      var scrollDelta = 0;

      function checkHeaderPos() {
        if ( !mainnav ) return;
        scrollTop = window.scrollY;
        scrollDelta = scrollTop - lastScrollTop;
        if ( scrollTop > 40 ) {
          mainnav.classList.remove('top');
          mainnav.classList.remove('top--ready');
          setTimeout(function() {
            mainnav.classList.remove('top--ready');
          }, 510);
          if ( scrollDelta > 0 ) {
            mainnav.classList.add('scrolled');
            mainnav.classList.add('not-top');
            setTimeout(function() {
              mainnav.classList.add('not-top--ready');
            }, 500);
          } else {
            mainnav.classList.remove('scrolled');
          }
        } else {
          mainnav.classList.remove('not-top');
          mainnav.classList.remove('scrolled');
          mainnav.classList.add('top');
          setTimeout(function() {
            mainnav.classList.remove('not-top--ready');
          }, 500);
          setTimeout(function() {
            mainnav.classList.add('top--ready');
          }, 510);
        }
        lastScrollTop = scrollTop; // update last scroll top value
      }
      checkHeaderPos();

      


      function closeNav() {
        if ( document.body.classList.contains('nav-open') ) {
          setTimeout(function() {
            document.body.classList.remove('nav-open');
          }, 0);

          document.querySelector('.js-toggle-nav').setAttribute('aria-expanded', 'false');
        }
      }
      function toggleNav(e){
        e.preventDefault();
        if ( !document.body.classList.contains('nav-open') ) {
          lockScroll();
          document.querySelector('.js-toggle-nav').setAttribute('aria-expanded', 'true');
          setTimeout(function() {
            document.body.classList.add('nav-open');
          }, 250);
        } else {
          closeNav();
          unLockScroll();
        }
      }
      function closeAllOpenSubmenus() {
        for (let activeMenuItem of document.querySelectorAll('.menu-item.active')) {
          activeMenuItem.classList.remove('active');
        }
      }
      /*var navParents = document.querySelectorAll('.menu-item-has-children > a');
      for (i = 0; i < navParents.length; i++) {
        Utils.addEvent(navParents[i], 'click', function() {

        });
      }*/

      // nav toggle
      Utils.addEvent(document.querySelector('.js-toggle-nav'), 'click', toggleNav);
      Utils.addEvent(document.querySelector('main'), 'click', closeNav);
      Utils.addEvent(document.querySelector('.js-close-nav'), 'click', function(e) {
        e.preventDefault();
        closeNav();
      });

      var mobileNavInternalLiks = document.querySelectorAll('.mobile-nav a[href*="#"]:not([href="#"])');
      for (let mobileNavInternalLik of mobileNavInternalLiks) {
        Utils.addEvent(mobileNavInternalLik, 'click', function(e) {
          closeNav();
          unLockScroll();
        });
        Utils.addEvent(mobileNavInternalLik, 'touchend', function(e) {
          closeNav();
          unLockScroll();
        });
        
      }
      
      var menuExternalLinks = document.querySelectorAll('.main-nav .link-external, .mobile-nav .link-external, footer .ubermenu .link-external, .main-nav .link-external--desktop, .mobile-nav .link-external--desktop, footer .ubermenu .link-external--desktop, .main-nav .link-external--mobile, .mobile-nav .link-external--mobile, footer .ubermenu .link-external--mobile');
      for (let externalLink of menuExternalLinks) {
        let externalLinkIndicator = document.createElementNS("http://www.w3.org/2000/svg",'svg');
        externalLinkIndicator.innerHTML = '<use xlink:href="#link-external" />';
        externalLinkIndicator.classList.add('menu-external-indicator');

        if ( !externalLink.querySelector('.menu-external-indicator') ) {
          
          if ( externalLink.querySelector('span') ) {
            externalLink.querySelector('span').append(externalLinkIndicator);
          } else {
            externalLink.append(externalLinkIndicator);
          }
        }
        
      }


      var menuLinks = document.querySelectorAll('.ubermenu a');
      for (let menuLink of menuLinks) {
        let externalLinkIndicator = document.createElementNS("http://www.w3.org/2000/svg",'svg');
        externalLinkIndicator.innerHTML = '<use xlink:href="#link-external" />';
        externalLinkIndicator.classList.add('menu-external-indicator');
        

        if ( !originReg.test(menuLink.href) && !menuLink.querySelector('.menu-external-indicator') ) {
          menuLink.querySelector('span').append(externalLinkIndicator);
        }
        
      }
        
      // SUB MENUS
      var submenuLinks = document.querySelectorAll('.menu-item-has-children > a, .ubermenu-item-has-children > .ubermenu-target');
      for (let submenuLink of submenuLinks) {

        // Add submenu indicators to menu items
        let submenuIndicator = document.createElementNS("http://www.w3.org/2000/svg",'svg');
        submenuIndicator.innerHTML = '<use xlink:href="#chevron-down-med" />';
        submenuIndicator.classList.add('menu-submenu-indicator');
        submenuLink.append(submenuIndicator);

        // Open & close submenus (mobile devices only)
        Utils.addEvent(submenuLink, 'click', function(e) {
          
          if ( window.innerWidth < mobileMenuBreakpoint ) {
            if ( this.parentElement.classList.contains('active') ) {
              this.parentElement.classList.remove('active');
            } else {
              e.preventDefault();
              this.parentElement.classList.add('active');
            }
          }
        });

      }


      var submenuLinkToggles = document.querySelectorAll('.menu-submenu-indicator');
      for (let submenuLinkToggle of submenuLinkToggles) {
        Utils.addEvent(submenuLinkToggle, 'click', function(e) {
          e.preventDefault();
          e.stopPropagation();
          if ( window.innerWidth < mobileMenuBreakpoint ) {
            if ( this.parentElement.parentElement.classList.contains('active') ) {
              this.parentElement.parentElement.classList.remove('active');
            } else {
              this.parentElement.parentElement.classList.add('active');
            }
          }
        });
      }
     
      


      // Copy login button to the end of body
      let loginButton = document.querySelector('.login-button');
      if (loginButton) {
        document.body.appendChild(loginButton.cloneNode(true));
      }
      
      
      // menu functions
      var productMenuToggle = document.querySelector('.site-footer__products .menu-item > a');

      function closeProductMenu() {
        productMenuToggle.parentElement.classList.remove('active');
      }

      Utils.addEvent(productMenuToggle, 'click', function(e) {
        if ( this.parentElement.classList.contains('active') ) {
          closeProductMenu();
        } else {
          this.parentElement.classList.add('active');
        }
        e.preventDefault();
        e.stopPropagation();
      });


      // Close all menus when clickin on body when menus are open
      Utils.addEvent(document.querySelector('body'), 'click', function(e) {
        closeAllOpenSubmenus();
        closeNav();
        closeProductMenu();
      });
      Utils.addEvent(document.querySelector('.header'), 'click', function(e) {
        closeAllOpenSubmenus();
        e.stopPropagation();
      });
      // Prevent body click event on selected navigation elements
      Utils.addEvent(document.querySelector('.mobile-nav'), 'click', function(e) {
        e.stopPropagation();
      });
      Utils.addEvent(document.querySelector('.site-footer__products ul'), 'click', function(e) {
        e.stopPropagation();
      });
      

      // Count reading times for posts
      const contentEl = document.querySelector('.single__content');
      const readTimeEl = document.querySelector('.read-time-val');
      if ( contentEl && readTimeEl ) {

        let textContentCopy = contentEl.cloneNode(true);

        const containerEls = textContentCopy.querySelectorAll('.l-container');
        for (let containerEl of containerEls) {
          containerEl.remove();
        }
        const styleEls = textContentCopy.querySelectorAll('style');
        for (let styleEl of styleEls) {
          styleEl.remove();
        }

        let text = textContentCopy.textContent;
        text = text.replace(/\s+/g,' ').trim();

        const wordsPerMinute = 200; // avg speed of words per minute
        let speed = 0;
        const textLength = text.split(" ").length;

        if ( textLength > 0 ) {
          speed = Math.ceil(textLength / wordsPerMinute);
        }
        readTimeEl.innerHTML = speed;
      }

      // Generate table of contents for [toc] shortcode
      const tocEl = document.querySelector('.toc__content');
      if ( tocEl ) {
        let tocCount = 1;
        let tocContent = '<ol>';
        const contentHeadings = tocEl.parentElement.parentElement.querySelectorAll('h2');

        for (let contentHeading of contentHeadings) {
          tocContent += '<li><a href="#' + contentHeading.getAttribute('id') + '">' + contentHeading.textContent + '</a></li>';
          tocCount++;
        }
        tocContent += '</ol>';
        tocEl.innerHTML = tocContent;
      }




      /*var tooltipToggles = document.querySelectorAll('.tip');
      for (let tooltipToggle of tooltipToggles) {

        const tooltipContent = tooltipToggle.querySelector('.tip-content');
 
        Utils.addEvent(tooltipToggle, 'mouseenter', function(e) {
          const tooltipBcr = tooltipToggle.getBoundingClientRect();
          const tooltipX = tooltipBcr.left;
          const tooltipY = tooltipBcr.top;
          tooltipContent.classList.add('active');
          //tooltipContent.style.setProperty('--x', tooltipX + 'px');
          //tooltipContent.style.setProperty('--y', tooltipY + 'px');
          //e.preventDefault();
          //e.stopPropagation();
        });

        Utils.addEvent(tooltipToggle, 'mouseleave', function(e) {
          tooltipContent.classList.remove('active');
        });
      }*/



      var debounce = function(func, wait, immediate) {
          var timeout;
          return function() {
              var context = this, args = arguments;
              clearTimeout(timeout);
              timeout = setTimeout(function() {
                  timeout = null;
                  if (!immediate) func.apply(context, args);
              }, wait);
              if (immediate && !timeout) func.apply(context, args);
          };
      };

      // debounced resize event
      var resizeDebounce = debounce(function() {

      }, 100);
      window.addEventListener('resize', resizeDebounce);


      // scroll function
      var scrollDebounce = debounce(function() {
        checkHeaderPos();
      }, 10);
      window.addEventListener('scroll', scrollDebounce);



    },
    finalize: function() {
      // JavaScript to be fired on all pages, after page specific JS is fired
    }
  },
  // Home page
  'home': {
    init: function() {
      // JavaScript to be fired on the home page
    },
    finalize: function() {
      // JavaScript to be fired on the home page, after the init JS
    }
  }
};

// The routing fires all common scripts, followed by the page specific scripts.
// Add additional events for more control over timing e.g. a finalize event
var UTIL = {
  fire: function(func, funcname, args) {
    var fire;
    var namespace = Base;
    funcname = (funcname === undefined) ? 'init' : funcname;
    fire = func !== '';
    fire = fire && namespace[func];
    fire = fire && typeof namespace[func][funcname] === 'function';

    if (fire) {
      namespace[func][funcname](args);
    }
  },
  loadEvents: function() {
    // Fire common init JS
    UTIL.fire('common');

    // Fire page-specific init JS, and then finalize JS
    var pageClasses = document.body.className.replace(/-/g, '_').split(/\s+/);
    for (let pageClass of pageClasses) {
      UTIL.fire(pageClass);
      UTIL.fire(pageClass, 'finalize');
    }

    // Fire common finalize JS
    UTIL.fire('common', 'finalize');
  }
};

// Load Events
document.addEventListener('DOMContentLoaded', UTIL.loadEvents);